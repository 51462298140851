import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layouts";
import Seo from "../components/seo";
import ProductCard from "../components/collections/productcard";
import BreadCrumb from "../components/common/breadcrumb";

const Products = ({ data }) => {
  const collection = data?.shopifyCollection;
  return (
    <Layout>
      <Seo
        data={{
          title: collection.title,
          description: collection.description,
        }}
      />
      <div className="mt-20">
        <div className="relative max-w-[1140px] w-full mx-auto box-border show fadeIn my-5 sm:my-10 px-5 lg:px-10">
          <h1 className="text-3xl sm:text-[40px] font-bold text-secondary pt-10 pb-[10px] text-center mb-3 uppercase ">
            {collection.title}
          </h1>
          <p className="text-xl sm:text-2xl pb-5 tracking-[2px] font-normal text-center leading-6 font-secondary sm:leading-[30px] text-secondary">
            {collection.description}
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-10">
            {collection.products.map((product) => (
              <ProductCard key={product.handle} product={product} />
            ))}
          </div>
        </div>
      </div>
      <BreadCrumb
        path={{
          breadLink: [
            { nameLink: "Collections", breadPath: "/collections" },
            {
              nameLink: collection.title,
              breadPath: `/collections/${collection.handle}`,
            },
          ],
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      title
      handle
      description
      products {
        title
        handle
        media {
          preview {
            image {
              altText
              gatsbyImageData(formats: WEBP, layout: CONSTRAINED)
            }
          }
        }
        priceRangeV2 {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
        }
      }
    }
  }
`;

export default Products;
