import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { getPrice } from "../../utils/getPrice";
import { useCartStore } from "../../state/cart";

const ProductCard = ({ product }) => {
  const { addItemToCart, isLoading } = useCartStore((state) => state);

  async function handleAddToCart() {
    const response = await addItemToCart({
      id: product.variants[0].shopifyId,
      quantity: 1,
    });
  }
  return (
    <div className="relative pb-4 w-full h-full group px-3">
      <div className="relative w-full aspect-square overflow-hidden border-[0.5px] border-solid border-secondary/50 rounded-md before:contents-[''] before:w-full before:h-full before:pt-[100%]">
        <Link
          to={`/product/${product.handle}`}
          className="absolute top-0 left-0 w-full h-full transition-all duration-300 ease-linear"
        >
          <GatsbyImage
            image={product.media[0]?.preview.image.gatsbyImageData}
            alt={product.title}
            objectFit="cover"
            className="w-full h-full object-cover"
          />
        </Link>
        {product.media[1] && (
          <Link
            to={`/product/${product.handle}`}
            className="relative w-full h-full opacity-0 group-hover:opacity-100 transition-all duration-300 ease-linear z-[1]"
          >
            <GatsbyImage
              image={product.media[1].preview.image.gatsbyImageData}
              alt={product.title}
              objectFit="cover"
              className="w-full h-full object-cover"
            />
          </Link>
        )}
        <button
          disabled={isLoading}
          onClick={handleAddToCart}
          className="flex items-center justify-center absolute bg-secondary w-full bottom-0 h-8 md:h-12 cursor-pointer rounded-b-md opacity-100 md:opacity-0 text-white
            z-10 transform md:translate-y-[30px] transition-all duration-200 ease-linear group-hover:opacity-100 group-hover:translate-y-0"
        >
          <span className="m-auto text-sm sm:text-base md:text-lg uppercase font-medium">
            Quick add
          </span>
        </button>
      </div>
      <div className="">
        <h2 className="text-lg pt-2 lg:text-xl text-[#666666] font-bold uppercase mb-1">
          {product.title}
        </h2>
        <h3 className="text-base font-semibold tracking-[1px] text-[#666666]">
          {getPrice(product.priceRangeV2)}
        </h3>
      </div>
    </div>
  );
};

export default ProductCard;
